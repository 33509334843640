import '../styles/index.scss';

const routeConfig = [
    {
        "url": "",
        "path": ""
    },
    {
        "url": "https://ttt-oto.web.app/",
        "name": "Tic Tac Toe",
        "path" : "ttt"
    }
];

 window.onhashchange = () => {
    onHashChange();
 };

 window.onload = () => {
    onHashChange();
 };

 function onHashChange() {
    const currentHash = window.location.hash;
    const gameView = document.querySelector("#game-view");
    const route = currentHash.replace(/^#\/games\//, "");
    const gameConfig = routeConfig.filter(config => route === config.path);
    const gameUrl = gameConfig[0].url;
    console.log(gameUrl);
    const gameFrame = document.querySelector("#game-frame");
    const header = document.querySelector(".header");
    const games = document.querySelector("#games");
    const logo = document.querySelector(".logo");

    if (gameUrl === "") { // home page
        header.style.display = "flex";
        gameView.style.display = "none";
        logo.style.padding = "10px";
        games.style.display = "block";
    } else {
        header.style.display = "none";
        games.style.display = "none";
        gameView.style.display = "block";
        gameFrame.setAttribute("src", gameUrl);
    }
}
